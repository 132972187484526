<template>
    <div class="full-height-layout fill">
        <mercur-card class="full-height-layout fill mx-4 mb-3">
            <h2 class="font-weight-normal">
                <span v-if="isEdit">Edit contact person</span>
                <span v-else>Add contact person</span>
            </h2>

            <div class="row">
                <div class="col-6">
                    <mercur-input
                        v-model="$v.contactPerson.lastName.$model"
                        required
                        :disabled="loading"
                        :class="{'form-invalid': $v.contactPerson.lastName.$error}">
                        Last name
                        <template slot="note">
                            <span class="form-error" v-if="!$v.contactPerson.lastName.required">Last name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.contactPerson.firstName.$model"
                        :disabled="loading"
                        :class="{'form-invalid': $v.contactPerson.firstName.$error}">
                        First name
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.contactPerson.phoneLandLine.$model"
                        type="tel"
                        :disabled="loading"
                        :class="{'form-invalid': $v.contactPerson.phoneLandLine.$error}">
                        Phone (landline)
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.contactPerson.phoneMobile.$model"
                        type="tel"
                        :disabled="loading"
                        :class="{'form-invalid': $v.contactPerson.phoneMobile.$error}">
                        Phone (mobile)
                    </mercur-input>
                </div>
                <div class="col-6">
                    <mercur-input
                        v-model="$v.contactPerson.emailAddress.$model"
                        type="email"
                        :disabled="loading"
                        :class="{'form-invalid': $v.contactPerson.emailAddress.$error}">
                        Email address
                        <template slot="note">
                            <span class="form-error" v-if="!$v.contactPerson.emailAddress.email">Email address is not valid</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="col-6">
                    <v-select
                        v-model="$v.contactPerson.roles.$model"
                        placeholder="Roles"
                        :multiple="true"
                        :taggable="true"
                        :select-on-tab="true"
                        @keypress.enter.stop.prevent="() => {}"
                        :disabled="loading"
                    >
                        <div slot="no-options"><small>Type a role and press enter to add.</small></div>
                    </v-select>
                </div>
            </div>

            <mercur-button @click="submitContactPerson" :disabled="$v.$invalid || loading" class="btn btn-raised btn-yellow text-uppercase">Submit</mercur-button>
        </mercur-card>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import { validationMixin } from 'vuelidate'
import {
    required,
    email,
} from 'vuelidate/lib/validators'
import { stripProperties } from '@/components/utils/Utils'
import vSelect from 'vue-select'
export default {
    name: 'SupplierContactPersonAdd',
    components: { vSelect },
    mixins: [ validationMixin ],
    validations: {
        contactPerson: {
            lastName: {
                required,
            },
            firstName: {},
            phoneLandLine: {},
            phoneMobile: {},
            emailAddress: {
                email,
            },
            roles: {},
        },
    },
    data () {
        return {
            loading: false,
            localContactPerson: {
                lastName: null,
                firstName: null,
                phoneLandLine: null,
                phoneMobile: null,
                emailAddress: null,
                roles: [],
            },
        }
    },
    computed: {
        contactPerson () {
            if (this.isEdit) {
                return this.$store.getters['contactPersons/getContactPersonBySupplierIdAndContactPersonId'](this.supplierId, this.$route.params.contactPersonId)
            }

            return this.localContactPerson
        },
        isEdit () {
            return !!this.$route.params.contactPersonId
        },
        formAction () {
            let url = CONFIG.API.ROUTES.SUPPLIERS.CONTACT_PERSONS.ADD

            if (this.isEdit) {
                url = CONFIG.API.ROUTES.SUPPLIERS.CONTACT_PERSONS.UPDATE.replace('{contactPersonId}', this.$route.params.contactPersonId)
            }

            return url.replace('{supplierId}', this.supplierId)
        },
    },

    methods: {
        submitContactPerson () {
            const data = stripProperties(this.contactPerson)

            this.addJob(this.formAction)
            this.loading = true

            this.$api.post(this.formAction, data).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Contact person was saved`,
                })

                setTimeout(() => {
                    this.$router.push({
                        name: 'SupplierContactPersons',
                        params: {
                            supplierId: this.supplierId,
                        },
                    })
                }, 100)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving contact person failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(this.formAction)
            })
        },
        fetchContactPerson () {
            if (this.isEdit === false) {
                return
            }

            this.loading = true
            this.$store.dispatch('contactPersons/fetchSupplierContactPersonByContactPersonId', {
                supplierId: this.supplierId,
                contactPersonId: this.$route.params.contactPersonId,
            })
        },
    },
    watch: {
        contactPerson (value) {
            if (value) {
                this.loading = false
            }
        },
    },
    created () {
        this.fetchContactPerson()
    },
}
</script>
